import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { globalFilterActions } from "../../../store/slices/globalFilters";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import switchrole from "../../../assets/images/icons/switch-role-icon.svg";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { userAuthoritiesActions } from "../../../store/slices/userRoles";
import { useNavigate } from "react-router-dom";

type Props = {};

const RolesGlobalFilter = (props: Props) => {
const allRoles = localStorage.getItem("allRoles") && JSON.parse(localStorage.getItem("allRoles"));
 const instituteId: any = localStorage.getItem("institute")
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUserRole = useSelector(
    (state) => state.globalFilters.currentUserRole
  );
  const currentRolesList = useSelector((state: any) => state.userInfo);
  const [selectedValue, setSelectedValue] = useState("");
  const [dashboardRoles, setDashboardRoles] = useState([]);

  const allRolesAuthorities = useMemo(() => {
    const authorities = localStorage.getItem("allRolesAuthorities");
    if (authorities) {
      try {
        return JSON.parse(authorities);
      } catch (error) {
        console.error("Failed to parse JSON:", error);
        return null;
      }
    } else {
      console.log("No data found for 'allRolesAuthorities' in localStorage.");
      return null;
    }
  }, []);

  useEffect(() => {
    if (
      allRoles[instituteId] !== undefined &&
      allRoles[instituteId].length > 0
    ) {
      const dashboardRoles = allRoles[instituteId].filter(
        (el: any) =>
        el.shortName === "student" ||
        el.shortName === "teacher" ||
        el.shortName === "editingteacher" ||
        el.shortName === "instituteadmin" ||
        el.shortName === "departmenthead" ||
        el.shortName === "programhead" ||
        el.shortName === "admin"
      );
      setDashboardRoles(dashboardRoles);

      if (dashboardRoles.length > 0) {
        const priorityOrder = [
          "admin",
          "instituteadmin",
          "departmenthead",
          "programhead",
          "editingteacher",
          "teacher",
          "student",
        ];
        let highestPriorityRole = null;

        for (const roleName of priorityOrder) {
          highestPriorityRole = dashboardRoles.find(
            (role: { shortName: string }) => role.shortName === roleName
          );
          if (highestPriorityRole) {
            break;
          }
        }
        setSelectedValue(highestPriorityRole.id);
        dispatch(
          globalFilterActions.currentUserRole({
            id: highestPriorityRole.id,
            shortName: highestPriorityRole.shortName,
            rolePriority: highestPriorityRole.rolePriority
          })
        );
        localStorage.setItem(
          "currentUserRole",
          JSON.stringify({
            id: highestPriorityRole.id,
            shortName: highestPriorityRole.shortName,
            rolePriority: highestPriorityRole.rolePriority,
          })
        );

        // ====================================================
        dispatch(userAuthoritiesActions.updateUserAuthorities(allRolesAuthorities[highestPriorityRole.id]));
        localStorage.setItem(
          "userAuthorities",JSON.stringify(allRolesAuthorities[highestPriorityRole.id])
        );
      }
  
      // if (currentUserRole.id === 0) {
      //   console.log("currentUserRole.id !== 0 106")
      //   setSelectedValue(currentUserRole.id);
      //   localStorage.setItem(
      //     "currentUserRole",
      //     JSON.stringify({
      //       id: currentUserRole.id,
      //       shortName: currentUserRole.shortName,
      //       rolePriority: currentUserRole.rolePriority,
      //     })
      //   );

      //   // ====================================================
      //   dispatch(userAuthoritiesActions.updateUserAuthorities(allRolesAuthorities[currentUserRole.id]));
      //   localStorage.setItem(
      //     "userAuthorities",JSON.stringify(allRolesAuthorities[currentUserRole.id])
      //   );
      // }
    }else{
      console.log("in else role filter")
      dispatch(
        globalFilterActions.currentUserRole({
          id: 0,
          shortName: "",
          rolePriority: ""
        })
      );
      localStorage.setItem(
        "currentUserRole",
        JSON.stringify({
          id:0,
          shortName: "",
          rolePriority: "",
        })
      );
    }
  }, [currentRolesList]);


  

  const getCurrentValue = (e: any) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const roleShortName = selectedOption.dataset.name;
    const rolePriority = selectedOption.dataset.priority;
    setSelectedValue(e.target.value);
    dispatch(
      globalFilterActions.currentUserRole({
        id: e.target.value,
        shortName: roleShortName,
        rolePriority: rolePriority
      })
    );
    localStorage.setItem(
      "currentUserRole",
      JSON.stringify({ id: e.target.value, shortName: roleShortName, rolePriority: rolePriority })
    );

    // ====================================================
    dispatch(userAuthoritiesActions.updateUserAuthorities(allRolesAuthorities[e.target.value]));
    localStorage.setItem(
      "userAuthorities",JSON.stringify(allRolesAuthorities[e.target.value])
    );

    // redirect to the dashboard || performance according to user role
    pageRedirectFn()
  };

  // redirect to the dashboard || performance according to user role
  const pageRedirectFn = () => {
    const currentRole = localStorage.getItem('currentUserRole');
    if (currentRole !== null && currentRole !== "") {
      try {
        const parsedRole = JSON.parse(currentRole);
        if (['student', 'teacher', 'editingteacher'].includes(parsedRole.shortName)) {
          navigate('/dashboard');
        } else {
          navigate('/performance');
        }
      } catch (error) {
        console.error("Error parsing role:", error);
        // Handle invalid JSON if needed
      }
    } else {
      console.log("Role not found");
      // You can add fallback navigation logic here if needed
    }
  }

  return (
    <>
      {dashboardRoles.length > 1 ? (
        <div className="switch-role">
          <OverlayTrigger
            trigger={["click"]}
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">{t('role.switchRole')}</Popover.Header>
                <Popover.Body>
                  <select
                    className="form-select"
                    value={selectedValue}
                    onChange={getCurrentValue}
                  >
                    {dashboardRoles.map((el: any, index: number) => (
                      <option
                        key={index}
                        value={el.id}
                        data-name={el.shortName}
                        data-priority={el.rolePriority}
                      >
                        {el.name}
                      </option>
                    ))}
                  </select>
                </Popover.Body>
              </Popover>
            }
            rootClose
          >
            <Button
              variant="link"
              className="head-icon my-role-element"
              data-tooltip-variant="light"
              title=""
            >
              <img src={switchrole} alt="Switch Role" />
            </Button>
          </OverlayTrigger>
          <Tooltip
            anchorSelect=".my-role-element"
            content={t('role.switchRole')}
            place="bottom"
            style={{ zIndex: 2 }}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default RolesGlobalFilter;
