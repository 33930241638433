import "./style.scss";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import helpdeskIcon from "../../assets/images/icons/helpdesk.svg";
import calendarIcon from "../../assets/images/icons/calendar.svg";
import dashboardIcon from "../../assets/images/icons/dashboard.svg";
import gradeboolIcon from "../../assets/images/icons/gradebook.svg";
import siteadminIcon from "../../assets/images/icons/siteadmin.svg";
import attendanceIcon from "../../assets/images/icons/attendance.svg";
import performanceIcon from "../../assets/images/icons/performance.svg";
import PriceRequestModal from "../../widgets/priceRequestModal/PriceRequestModal";
import { useTranslation } from "react-i18next"; // Import the translation hook

const HeaderTabs = ({ activeTab }: any) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation(); // Use the translation hook

  const menuPermission = useSelector(
    (state: any) => state.userAuthorities.permissions.menu
  );

  const authenticatedUserPermission = useSelector(
    (state: any) => state.authenticatedUser.permissions.menu
  );

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  const handleShowModal: () => void = () => setShowModal(true); // Show modal on click
  const handleCloseModal: () => void = () => setShowModal(false);

  return (
    <div className="site-header-tab">
      <Nav as="ul">
        {((menuPermission.dashboard.canView &&
          currentUserRole.shortName !== "admin") ||
          (authenticatedUserPermission.dashboard.canView &&
            currentUserRole.id === 0)) && (
          <Nav.Item as="li">
            <Link
              to="/dashboard"
              className={
                activeTab === "studentdashboard"
                  ? "active-tab"
                  : activeTab === "teacherdashboard"
                  ? "active-tab"
                  : ""
              }
            >
              <img src={dashboardIcon} alt={t("common.dashboard")} />
              <span>{t("common.dashboard")}</span>
            </Link>
          </Nav.Item>
        )}
        {menuPermission.performance.canView && currentUserRole.id !== 0 ? (
          <Nav.Item as="li">
            <Link
              to="/performance"
              className={activeTab === "performance" ? "active-tab" : ""}
            >
              <img src={performanceIcon} alt={t("common.performance")} />
              <span>{t("common.performance")}</span>
            </Link>
          </Nav.Item>
        ) : (
          authenticatedUserPermission.dashboard.canView &&
          currentUserRole.id === 0 && (
            <Nav.Item as="li" onClick={handleShowModal}>
              <Link
                to=""
                className={activeTab === "performance" ? "active-tab" : ""}
              >
                <img src={performanceIcon} alt={t("common.performance")} />
                <span>{t("common.performance")}</span>
              </Link>
            </Nav.Item>
          )
        )}
        {menuPermission.calendar.canView && currentUserRole.id !== 0 && (
          <Nav.Item as="li">
            <Link
              to="/calender"
              className={activeTab === "calender" ? "active-tab" : ""}
            >
              <img src={calendarIcon} alt={t("common.calendar")} />
              <span>{t("common.calendar")}</span>
            </Link>
          </Nav.Item>
        )}
        {menuPermission.gradebook.canView && currentUserRole.id !== 0 ? (
          <Nav.Item as="li">
            <Link
              to="/gradebook"
              className={activeTab === "gradebook" ? "active-tab" : ""}
            >
              <img src={gradeboolIcon} alt={t("common.gradebook")} />
              <span>{t("common.gradebook")}</span>
            </Link>
          </Nav.Item>
        ) : (
          authenticatedUserPermission.gradebook.canView &&
          currentUserRole.id === 0 && (
            <Nav.Item as="li" onClick={handleShowModal}>
              <Link
                to=""
                className={activeTab === "gradebook" ? "active-tab" : ""}
              >
                <img src={gradeboolIcon} alt={t("common.gradebook")} />
                <span>{t("common.gradebook")}</span>
              </Link>
            </Nav.Item>
          )
        )}
        {menuPermission.attendance.canView && currentUserRole.id !== 0 ? (
          <Nav.Item as="li">
            <Link
              to="/attendance"
              className={activeTab === "attendance" ? "active-tab" : ""}
            >
              <img src={attendanceIcon} alt={t("common.attendance")} />
              <span>{t("common.attendance")}</span>
            </Link>
          </Nav.Item>
        ) : (
          authenticatedUserPermission.gradebook.canView &&
          currentUserRole.id === 0 && (
            <Nav.Item as="li" onClick={handleShowModal}>
              <Link
                to=""
                className={activeTab === "attendance" ? "active-tab" : ""}
              >
                <img src={attendanceIcon} alt={t("common.attendance")} />
                <span>{t("common.attendance")}</span>
              </Link>
            </Nav.Item>
          )
        )}
        <Nav.Item as="li">
          <Link
            to="/helpdesk"
            className={activeTab === "helpdesk" ? "active-tab" : ""}
          >
            <img src={helpdeskIcon} alt={t("common.helpdesk")} />
            <span>{t("common.helpdesk")}</span>
          </Link>
        </Nav.Item>
        {menuPermission.admin.canView && (
          <Nav.Item as="li">
            <Link
              to="/siteadmin"
              className={activeTab === "siteadmin" ? "active-tab" : ""}
            >
              <img src={siteadminIcon} alt={t("common.administration")} />
              <span>{t("common.administration")}</span>
            </Link>
          </Nav.Item>
        )}
      </Nav>
      <PriceRequestModal
        show={showModal}
        handleClose={handleCloseModal}
        currentUserRole={currentUserRole}
      />
    </div>
  );
};

export default HeaderTabs;
