import React, { useEffect, useState } from 'react'
import View from './view'
import { deleteData as deleteCasteData, getData } from '../../../../adapters/coreservices';
import DeleteAlert from '../../../../widgets/alert/deleteAlert';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { pagination } from '../../../../utils/pagination';


type Caste = {
  name: string;
  shortName: string;
  published: string;
};

function ManageCaste() {
  const dummyData = {
    items: [],
    pager: { totalElements: 0, totalPages: 0 },
  };
  const [casteData, setCasteData] = useState<any>(dummyData);
  const [apiStatus, setApiStatus] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [deleteId, setDeleteId] =   useState<number>(0);
  const [deleteRefresh, setDeleteRefresh] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [onDeleteAction, setOnDeleteAction] = useState("");
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [currentCasteForUpdate,setCurrentCasteForUpdate]= useState({
    id:0,
    name: "",
    shortName: "",
    // published: ""
})
  const { t } = useTranslation();
  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
  });
  const getCategoriesData = () => {
    setApiStatus(true)
    getData(`/caste`, filterUpdate)
      .then((res: any) => {
        if (res.data !== "" && res.status === 200) {
          setCasteData(res.data);
          setApiStatus(false)
        }
      })
      .catch((err: any) => {
        setApiStatus(false)
        console.log(err);
      });
  }


  const toggleModalShow: (status: boolean) => void = (status: boolean) => {
    setModalShow(status);
  };
  
  const updateDeleteRefresh = (status: boolean) => {
    setDeleteRefresh(status);
  };

  const deleteHandler = (userid: number) => {
    // refreshdata(false);
    setShowDeleteModal(true);
    setDeleteId(userid);
  };
  const deleteActionResponse = (action: string) => {
    setOnDeleteAction(action);
    setShowDeleteModal(false);
  };

  const editHandler = (data: Caste) => {
    setCurrentCasteForUpdate(prevState => ({
        ...prevState,
        ...data,
    }));
    toggleModalShow(true);
};

const cleanFormValues = () => {
  setCurrentCasteForUpdate({
    id:0,
    name:"",
    shortName:"",
    // published:""
});
};
  useEffect(() => {
    if (onDeleteAction === t('common.yes')) {
      updateDeleteRefresh(false);
      const endPoint = `/caste/${deleteId}`;
      deleteCasteData(endPoint)
        .then((res: any) => {
          if (res.status === 200) {
            Swal.fire({
              timer: 3000,
              width: "25em",
              color: "#666",
              icon: "success",
              background: "#e7eef5",
              showConfirmButton: false,
              text: t('prgMgt.catDel'),
            });
            updateDeleteRefresh(true);
          }
        })
        .catch((result: any) => {
          if (result.status === 500|| 400 || 404) {
            // setShowAlert(true);
            Swal.fire({
              timer: 4000,
              width: "25em",
              color: "#666",
              icon: "error",
              background: "#e7eef5",
              showConfirmButton: false,
              text: `${result.response.data.message}`,
            });
          }

        });
    }
    setOnDeleteAction("");
  }, [onDeleteAction]);

   // Get category Data from API === >>
   useEffect(() => {
    if (deleteRefresh === true) {
      getCategoriesData();
    }
  }, [deleteRefresh]);

  // Get category Data from API === >>
  useEffect(() => {
    getCategoriesData();
  }, [refreshData]);

  const refreshToggle = () => {
    setRefreshData(!refreshData);
  };

  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate({ ...filterUpdate, pageNumber: pageRequest });
  };
  
  return (
    <>
    <View
    apiStatus={apiStatus}
    modalShow={modalShow}
    casteData={casteData.items}
    setDeleteId={setDeleteId}
    deleteHandler={deleteHandler}
    editHandler={editHandler}
    newPageRequest={newPageRequest}
    toggleModalShow={toggleModalShow}
    cleanFormValues={cleanFormValues}
    updateDeleteRefresh={updateDeleteRefresh}
    currentCasteForUpdate={currentCasteForUpdate}
    refreshToggle={refreshToggle}
    getCategoriesData={getCategoriesData}
    filterUpdate={filterUpdate}
    totalPages={casteData.pager.totalPages}
    />
    <DeleteAlert
    show={showDeleteModal}
    onHide={() => setShowDeleteModal(false)}
    deleteActionResponse={deleteActionResponse}
    modalHeading={t('userMgt.mngCaste')}
    />
    </>
  )
}

export default ManageCaste