import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { globalFilterActions } from "../../../../store/slices/globalFilters";
import { getData } from "../../../../adapters/microservices";
import switchinstitute from "../../../../assets/images/icons/switch-institue-icon.svg";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Button } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { useTranslation } from "react-i18next";

const InstituteFilter = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentInstitute = useSelector(state => state.globalFilters.currentInstitute);
  const dummyData = {items: [], pager: {totalElements: 0, totalPages: 0}};
  const [institutes, setInstitutes] = useState(dummyData);
  const filters = {pageNumber: 0, pageSize : 50};
  const [selectedValue, setSelectedValue] = useState('');
  const currentUserRole = useSelector((state: any) => state.globalFilters.currentUserRole);

  const systemRoleExists =  JSON.parse(localStorage.getItem("systemRoles"));
  const idString = localStorage.getItem("allInstitute");
  const idArray = idString ? JSON.parse(idString).map(Number) : []; // Parse and convert to numbers
  
  useEffect(() => {
    const fetchInstitutes = async () => {
      try {
        const response = await axios.get(
          'https://api.microlearning.ballisticlearning.com/learning-service/api/v1/public/institutes?pageNumber=0&pageSize=30'
        );
  
        if (response.data) {
          const filteredArray = response.data.items.filter((obj) => 
            obj.locked !== false && 
          (Object.keys(systemRoleExists).length > 0 || idArray.includes(obj.id))
          );
  
          setInstitutes({
            items: filteredArray,
            pager: {
              totalElements: response.data.totalElements, // Adjust according to your API response
              totalPages: response.data.totalPages        // Adjust according to your API response
            }
          });
        }
      } catch (error) {
        console.error("Error fetching institutes:", error);
      }
    };
    if (currentUserRole.id !== 0) {
      getData('/institutes', filters)
        .then((result) => {
          if (result.status === 200 && result.data) {
            const filteredArray = result.data.items.filter((obj) => 
              obj.locked !== false && 
              (Object.keys(systemRoleExists).length > 0 || idArray.includes(obj.id))
          );
  
            setInstitutes({
              items: filteredArray,
              pager: {
                totalElements: result.data.totalElements, // Adjust if needed
                totalPages: result.data.totalPages         // Adjust if needed
              }
            });
            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      fetchInstitutes();
    }
  }, []); // Ensure dependencies are included

  useEffect(() => {
    if (institutes.items.length > 0) {
      if (currentInstitute === 0) {
        setSelectedValue(institutes.items[0].id);
        dispatch(globalFilterActions.currentInstitute(institutes.items[0].id))
        localStorage.setItem("institute", institutes.items[0].id);
        localStorage.setItem("instituteUrl", institutes.items[0].instanceUrl);
      } else {
        setSelectedValue(currentInstitute);
        localStorage.setItem("institute", currentInstitute);
        localStorage.setItem("instituteUrl", institutes.items.find((obj) => obj.id === currentInstitute)?.instanceUrl);
        // localStorage.setItem("institute", institutes.items[0].id);
      }
    }
  }, [institutes]);

  const getCurrentValue = (e : any) => {
    // const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedValue(e.target.value);
    dispatch(globalFilterActions.currentInstitute(e.target.value))
    localStorage.setItem("institute", e.target.value);
    window.location.reload();
  }

  return (
    <>
      <div className="select-institute">
        <OverlayTrigger
          rootClose
          trigger={['click']}
          placement="bottom"
          overlay={
            <Popover id="popover-basic">
              <Popover.Header as="h3">
                {institutes.items.length > 1
                ?
                t('common.switchInst')
                :
                t('common.inst')
                }
              </Popover.Header>
              <Popover.Body>
                {institutes.items.length > 1 ? 
                  <select 
                    className="form-select"
                    value={selectedValue} 
                    onChange={getCurrentValue}
                    aria-readonly
                    >
                    {institutes.items.map((el: any, index: number) => (
                      <option key={index} value={el.id} data-name={el.name}>{el.name}</option>
                      ))}
                  </select>
                  :
                  <b className="ms-2">{institutes.items[0]?.name}</b>
                }
              </Popover.Body>
            </Popover>
          }
        >
        <Button variant="link" className="head-icon my-institute-element" data-tooltip-variant="light" title="">
          <img src={switchinstitute} alt="Select Institute" />
        </Button>
        </OverlayTrigger>
        <Tooltip
            anchorSelect=".my-institute-element"
            content={t('common.selectInst')}
            place="bottom"
            style={{zIndex:2}}
          />
      </div>
    </>
  ); 
}

export default InstituteFilter;