import * as Yup from "yup";
import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import { Modal } from "react-bootstrap";
import "sweetalert2/src/sweetalert2.scss";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FieldLabel from "../../../../widgets/formInputFields/labels";
import FieldTypeText from "../../../../widgets/formInputFields/formTextField";
import FieldErrorMessage from "../../../../widgets/formInputFields/errorMessage";
import FieldTypeTextarea from "../../../../widgets/formInputFields/formTextareaField";
import CustomButton, { LoadingButton } from "../../../../widgets/formInputFields/buttons";
import TimerAlertBox from "../../../../widgets/alert/timerAlert";
import FieldTypeCheckbox from "../../../../widgets/formInputFields/formCheckboxField";
import {
  postData as addCasteData,
  putData,
} from "../../../../adapters/coreservices";;
const CasteModal = ({
  show,
  onHide,
  toggleModalShow,
  updateAddRefresh,
  currentCasteForUpdate,
  getCategoriesData
}: any) => {
  const { t } = useTranslation()

  // Formik Yup validation === >>>
  const categorySchema = Yup.object({
    name: Yup.string().min(1).required("Required feald"),
    shortName: Yup.string().min(1).required("Required feald"),
    // description: Yup.string().max(100).required(),
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ message: "", alertBoxColor: "" });
  const [formValues, setFormValues] = useState({
    name: "",
    shortName: "",
    // published: ""
  })

  useEffect(() => {
    const initialValues = {
      name: currentCasteForUpdate.name,
      shortName: currentCasteForUpdate.shortName,
      published: currentCasteForUpdate.published
    };
    setFormValues(initialValues);
  }, [currentCasteForUpdate]);



  const handleFormData = async (values: {}, { setSubmitting, resetForm }: any) => {
    setSubmitting(true);
    try {
      let res;
      const isAdding = currentCasteForUpdate.id === 0;
  
      if (isAdding) {
        res = await addCasteData(`/caste`, values);
        if (res.status === 201) {
          Swal.fire({
            timer: 3000,
            width: "25em",
            color: "#666",
            icon: "success",
            background: "#e7eef5",
            showConfirmButton: false,
            text: "Caste has been successfully added",
          });
          resetForm();
        }
      } else {
        res = await putData(`/caste/${currentCasteForUpdate.id}`, values);
        if (res.status === 200) {
          Swal.fire({
            timer: 3000,
            width: "25em",
            color: "#666",
            icon: "success",
            background: "#e7eef5",
            showConfirmButton: false,
            text: "Caste has been successfully updated",
          });
        }
      }
  
      if (res && (res.status === 201 || res.status === 200)) {
        updateAddRefresh();
        getCategoriesData();
        toggleModalShow(false);
      }
    } catch (err) {
      console.error(err);
      
      
      let errorMessage = "An error occurred. Please try again.";
      
      // Handle specific error statuses
      if (err.response) {
        if (err.response.status === 400) {
          setShowAlert(true);
          errorMessage = err.response.data.message;
        } else if (err.response.status === 500) {
          setShowAlert(true);
          errorMessage = err.response.data.message;
        } 
        // else {
        //   errorMessage = err.response.data.message || errorMessage;
        // }
      }
      
      setAlertMsg({
        message: errorMessage,
        alertBoxColor: "danger",
      });
    } finally {
      setSubmitting(false);
      setTimeout(() => {
        onHide();
        setShowAlert(false);
      }, 2000);
    }
  };
  


  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-design-wrapper"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {"Add Caste"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={formValues}
            validationSchema={categorySchema}
            onSubmit={(values, action) => {
              handleFormData(values, action);
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="mb-3">
                  <FieldLabel
                    htmlfor="name"
                    labelText={t('common.name')}
                    required="required"
                    star="*"
                  />
                  <FieldTypeText name="name" placeholder={t('common.name')} />
                  <FieldErrorMessage
                    errors={errors.name}
                    touched={touched.name}
                    msgText={t('prgMgt.nameMin')}
                  />
                </div>

                <div className="mb-3">
                  <FieldLabel
                    htmlfor="shortName"
                    labelText={"Short Name"}
                    required="required"
                    star="*"
                  />
                  <FieldTypeText name="shortName" placeholder={"Short Name"} />
                  <FieldErrorMessage
                    errors={errors.shortName}
                    touched={touched.shortName}
                    msgText={t('prgMgt.nameMin')}
                  />
                </div>

                {/* <div className="mb-3">
                  <FieldTypeCheckbox
                    name="published"
                    checkboxLabel="Published"
                  />{" "}
                  <FieldErrorMessage
                    errors={errors.published}
                    touched={touched.published}
                    msgText={t('naac.checkReqField')}
                  />
                </div> */}
                {isSubmitting === false ? (
                  <div className="modal-buttons">
                    <CustomButton
                      type="submit"
                      variant="primary"
                      isSubmitting={isSubmitting}
                      btnText={t('common.submit')}
                    />{" "}
                    <CustomButton
                      type="reset"
                      btnText={t('common.reset')}
                      variant="outline-secondary"
                    />
                  </div>
                ) : (
                  <LoadingButton
                    variant="primary"
                    btnText={t('common.submitting')}
                    className="modal-buttons"
                  />
                )}
              </Form>
            )}
          </Formik>
          <TimerAlertBox
            alertMsg={alertMsg.message}
            className="mt-3"
            variant={alertMsg.alertBoxColor}
            setShowAlert={setShowAlert}
            showAlert={showAlert}
          />
          {/* <p>Parent: {parent === 0 ? "Top" : editCategory.name}</p> */}
          {/* <p>Weight: {weight}</p> */}
        </Modal.Body>
        {/* <img src={WaveBottom} alt="WaveBottom" className="wavebg"/> */}
      </Modal>
    </>
  );
};

export default CasteModal;
