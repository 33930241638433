import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import "./style.scss"

const Footer = () => {

const { t } = useTranslation();
    return (
         <div className="site-footer mt-auto">
         <ul className="list-group list-group-horizontal">
             <li className="list-group-item">{t('footer.faqs')}</li>
             <li className="list-group-item">{t('footer.termsConditions')}</li>
             <li className="list-group-item">{t('footer.privacyPolicy')}</li>
             <li className="list-group-item">{t('footer.disclaimer')}</li>
             <li className="list-group-item">{t('footer.copyright')}</li>
         </ul>
     </div>
    );
}
export default Footer;
