import axios from 'axios';
import config from "../../utils/config";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import NewLoader from "../../widgets/loader";
import { useNavigate } from "react-router-dom";
import { getData } from "../../adapters/coreservices";
import UserContext from "../../features/context/user/user";
import React, { useContext, useEffect, useState } from "react";
import { globalAlertActions } from "../../store/slices/globalAlerts";
import { authenticatedUserActions } from "../../store/slices/userRoles";
import { createAxiosInstance } from "../../adapters/microservices/utils";
import { createCoreAxiosInstance } from "../../adapters/coreservices/utils";
import { globalUserInfoActions } from "../../store/slices/userInfo";
import { createHostelAxiosInstance } from "../../adapters/hostelservices/utils";
import { globalFilterActions } from '../../store/slices/globalFilters';

const AuthLogin = () => {
  const error = null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const [authCode, setAuthCode] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const redirectUri = config.REDIRECT_URI;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setAuthCode(params.code);
  }, []);

  useEffect(() => {
    axios
      .get(`${config.CORE_SERVICE_URL}/public/languages`)
      .then((result: any) => {
        if (result.data !== "" && result.status === 200) {
          localStorage.setItem("languages", JSON.stringify(result.data));
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (authCode !== "") {
      const VERIFY_URL = `${config.OAUTH2_URL}/api/verifycode?code=${authCode}&redirect_uri=${redirectUri}`;
      console.log('VERIFY_URL', VERIFY_URL);

      var requestOptions: any = {
        method: 'GET',
        redirect: 'follow'
      };

      axios.get(VERIFY_URL, requestOptions)
        .then((response) => {
          if (response.data === '') {
            console.log('verifycode , no token found', response);
            dispatch(globalAlertActions.globalAlert({ alertMsg: "User verification failed, please try again!", status: true }))
            navigate('/');
          } else {
            let result = response.data;
            setIsLoaded(true);
            if ('access_token' in result) {
              Object.entries(result).map(([key, value]: any) => {
                value = value.toString();
                sessionStorage.setItem(key, value);
                localStorage.setItem(key, value);  // added if app if reloaded for some url
              });

              createAxiosInstance(result.access_token);
              createCoreAxiosInstance(result.access_token);
              createHostelAxiosInstance(result.access_token);
              // config.WSTOKEN = config.ADMIN_MOODLE_TOKEN;
              config.WSTOKEN = result.access_token;
              config.OAUTH2_ACCESS_TOKEN = result.access_token;
              userCtx.setUserToken(config.WSTOKEN); // set WS TOKEN in context

              getData('/user-info', {}).then((res: any) => {
                if (res.data !== "" && res.status === 200) {
                  userCtx.setUserInfo(res.data) // set User-info data packet in context
                  let rolesWiseAuthorities: any = {};

                  const rolesKeys: any = Object.keys(res.data.roles);
                  let rolesData = [...res.data.roles[rolesKeys[rolesKeys.length - 1]]];

                  if (res.data.systemRoles !== undefined) {
                    Object.keys(res.data.systemRoles).forEach(key => {
                      rolesData.push(res.data.systemRoles[key]);
                    });
                    res.data.roles[rolesKeys[rolesKeys.length - 1]] = rolesData
                  }

                  Object.entries(res.data.authorities).forEach(([key, value]) => {
                    if (key !== "0") {
                      rolesWiseAuthorities[key] = value;
                    }
                  });


                  let allRolesWithSystemRole = { ...res.data.roles }; // Create a copy of roles
                  Object.entries(res.data.institutes).forEach(([key]) => {
                    if (key !== "0") {
                      // Check if the key exists in allRolesWithSystemRole and initialize if necessary
                      if (!allRolesWithSystemRole[key]) {
                        allRolesWithSystemRole[key] = [];
                      }

                      // Check if the system role is already present
                      if (Object.keys(res.data.systemRoles).length > 0) {
                        // Check if the system role is already present
                        const systemRoleExists = allRolesWithSystemRole[key].some((role: { id: any; }) => role.id === res.data.systemRoles["57"].id);
                        if (!systemRoleExists) {
                          // Push the system role into the respective institute's roles
                          allRolesWithSystemRole[key].push(res.data.systemRoles["57"]); // Add the system role
                        }
                      } else {
                        console.error("System role '57' is undefined.");
                      }
                    }
                  });


                  res.data !== "" &&localStorage.setItem("allRoles", JSON.stringify(allRolesWithSystemRole));
                  // to get all institute 
                  const keys: any = Object.keys(res.data.institutes);
                  localStorage.setItem("allInstitute", JSON.stringify(keys));
                  localStorage.setItem("institute", keys[keys.length - 1]);
                  dispatch(globalFilterActions.currentInstitute(keys[keys.length - 1]))

                  rolesWiseAuthorities !== undefined && localStorage.setItem('allRolesAuthorities', JSON.stringify(rolesWiseAuthorities));
                  // set prifered language as selected value
                  localStorage.setItem('selectedLanguages', JSON.stringify(res.data.language))
                  //Authenticated User
                  res.data.authorities[0] !== undefined && dispatch(authenticatedUserActions.updateUserAuthorities(res.data.authorities[0]));
                  res.data.authorities[0] !== undefined && localStorage.setItem('authenticatedUser', JSON.stringify(res.data.authorities[0]));

                  res.data !== "" && dispatch(globalUserInfoActions.userInfo(res.data));
                  res.data !== "" && localStorage.setItem('userInfo', JSON.stringify({ userInfo: res.data }))
                  res.data.roles[rolesKeys[rolesKeys.length - 1]] !== undefined && localStorage.setItem('roles', JSON.stringify(rolesData));
                  // Save systemRoles to local storage
                  res.data.systemRoles !== undefined && localStorage.setItem('systemRoles', JSON.stringify(res.data.systemRoles));

                  // Create a Set of institute keys for quick lookup
                  const instituteKeys = new Set(Object.keys(res.data.institutes));
                  // Iterate over the entries of res.data.roles
                  Object.entries(res.data.roles).forEach(([roleKey, roleValues]: any) => {
                    // Check if the current roleKey exists in the institutes
                    if (instituteKeys.has(roleKey)) {
                      roleValues.length === 0 && navigate('/dashboard')

                      // Iterate over the roleValues array
                      roleValues.forEach((item: any) => {
                        // Navigate based on the shortName of the item
                        if (item.shortName !== "" && !["student", "teacher", "editingteacher"].includes(item.shortName)) {
                          navigate("/performance");
                        } else {
                          navigate("/dashboard")
                        }
                        // if (item.shortName === "student" || item.shortName === "teacher" || item.shortName === "editingteacher") {
                        //   navigate("/dashboard");
                        //   console.log("inside dashboard")
                        // } else {
                        //   navigate("/performance");
                        //   console.log("inside performance")
                        // }
                      });
                    }
                  });

                  // Object.keys(res.data.institutes).forEach((instkey) => {
                  //   Object.entries(res.data.roles).forEach(([rolekey, roleValue]: any) => {
                  //     if(instkey === rolekey){
                  //       roleValue.map((item: any) => {
                  //         if(item.shortName === "student" || item.shortName === "teacher" || item.shortName === "editingTeacher"){
                  //           navigate("/dashboard")
                  //         }else{
                  //           navigate("/performance");
                  //         }
                  //       })
                  //     }
                  //   }) 
                  // })

                  // setTimeout(()=>{
                  // if(res.data.roles[1][0].shortName === 'student' || res.data.roles[1][0].shortName === 'teacher' || res.data.roles[1][0].shortName === 'editingTeacher'){
                  //   navigate("/dashboard");
                  // }
                  // if(res.data.roles[1][0].shortName !== 'student' || res.data.roles[1][0].shortName !== 'teacher' || res.data.roles[1][0].shortName !== 'editingTeacher'){
                  //   navigate("/performance");
                  // }
                  // }, 500);
                }
              })

            } else {
              dispatch(globalAlertActions.globalAlert({ alertMsg: "User verification failed, no token found!", status: true }));
              navigate('/');
            }
          }
          // Handle the response here
          console.log('axios response success', response);
        })
        .catch(error => {
          // Handle errors here
          console.error('axios response eeor', error);
        });
    }
  }, [authCode]);

  const loaderStyle = {
    display: "flex",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <React.Fragment>
      <Container style={loaderStyle}>
        <div>
          {(error && <div>{error}</div>) ||
            (!isLoaded && (
              <div>
                <NewLoader />
              </div>
            ))}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default AuthLogin;
