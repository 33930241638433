import React from "react";
import Mobile from "./mobile";
import Browser from "./browser";
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
  apiStatus: any,
  casteData: any,
  modalShow: any,
  setDeleteId: any,
  totalPages: any,
  filterUpdate: any,
  editHandler: any,
  refreshToggle: any,
  deleteHandler: any,
  newPageRequest: any,
  cleanFormValues: any,
  toggleModalShow: any
  getCategoriesData: any;
  currentCasteForUpdate: any,
};

const View = (props: Props) => {
  const commonProps = {
    apiStatus: props.apiStatus,
    casteData: props.casteData,
    modalShow: props.modalShow,
    setDeleteId: props.setDeleteId,
    totalPages: props.totalPages,
    filterUpdate: props.filterUpdate,
    editHandler: props.editHandler,
    refreshToggle: props.refreshToggle,
    deleteHandler: props.deleteHandler,
    currentCasteForUpdate: props.currentCasteForUpdate,
    newPageRequest: props.newPageRequest,
    cleanFormValues: props.cleanFormValues,
    toggleModalShow: props.toggleModalShow,
    getCategoriesData: props.getCategoriesData,
  };
  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  );
};

export default View;