import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route } from "react-router-dom";

// attendance route
const Attendance = React.lazy(() => import('../../../pages/attendance'))

// dashboard route
const DashboardNew = React.lazy(() => import('../../../pages/dashboardNew'))

// gradebook route
const GradeBook = React.lazy(() => import('../../../pages/gradeBook/mainIndex'))
const TeacherGradebook = React.lazy(() => import('../../../pages/teacherDashboard/gradebook/teacherGradebook'))
const GradeTable = React.lazy(() => import('../../../pages/gradeBook/teacherGradeBook/studentViewGradebook/index'))

// helpdesk route
const Helpdesk = React.lazy(() => import("../../../pages/helpdesk"))
const Helpdeskmanagement = React.lazy(() => import("../../../pages/siteAdminstration/helpdeskManagement"))
const ManageTopic = React.lazy(() => import( "../../../pages/siteAdminstration/helpdeskManagement/managetopic"))

// performance route
const Performance = React.lazy(() => import('../../../pages/siteAdminstration/performance'))
const CoursePerformanceDetails = React.lazy(() => import('../../../pages/siteAdminstration/performance/viewCourseAnalytics'))
const ProgramPerformanceDetails = React.lazy(() => import('../../../pages/siteAdminstration/performance/viewProgramAnalytics/index'))
const PerformanceCourseDetails = React.lazy(() => import('../../../pages/siteAdminstration/performance/roles/teacher/viewCourseAnalytics'))

const HeaderTabsRoute = () => {

  const currentUserRole = useSelector((state: any) => state.globalFilters.currentUserRole);
  const permissions = useSelector((state: any) => state.userAuthorities.permissions);
  const { canView } = permissions.menu.dashboard;
  
  return [

      <Route path="/dashboard" key="dashboard" element={ (canView && currentUserRole.shortName !== 'admin') || currentUserRole.id === 0  ? <DashboardNew /> : <Navigate to="/performance" />} />, // dashboard routes

      <Route path="/attendance" key="attendance" element={<Attendance />} />, // attendance route
      
      // gradebook route
      <Route path='/gradebook' element={<GradeBook />} />,
      <Route path='/teachergradebook' element={<TeacherGradebook />} />,
      <Route path='/studentgradeview/:courseId/:userId/:courseName' element={<GradeTable />} />,
      
      // helpdesk route
      <Route
      path="/helpdeskmanagement"
      key="helpdeskmanagement"
      element={<Helpdeskmanagement />}
      />,
      <Route path="/helpdesk" key="helpdesk" element={<Helpdesk />} />,
      <Route path="/managetopic" key="managetopic" element={<ManageTopic />} />,   
      
      // performance route
      <Route path="/performance" key="performance" element={<Performance />} />,
      <Route path="/performance/details" key="performance" element={<ProgramPerformanceDetails/>} />,
      <Route path="/program-details/:programid/:prgname" key="performance" element={<CoursePerformanceDetails/>} />,
      <Route path="/performance/course/details/:progId" key="performance" element={<PerformanceCourseDetails/>} />,
  ];
};

export default HeaderTabsRoute;